<template>
  <div class="container">
    <router-view/>
    <section class="footerSection english-font">
      <div class="content has-text-centered">
        <div class="columns is-flex is-centered">
          <figure class="image is-96x96 image-padding">
            <img src="../assets/Government_of_Bangladesh.png">
          </figure>
        </div>
        <p>Powered By <a class="foodqo" href="https://www.facebook.com/testcoronatoday/"  rel="noopener noreferrer nofollow" target="_blank">TestCorona.Today</a></p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
};
</script>
<style>
.image-padding{
  padding: 14px;
}
.footerSection {
  margin-top: 124px;
  margin-bottom: 16px;
}
.foodqo {
  color: #00d1b2 !important;
}
</style>
